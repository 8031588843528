import type { SafeStrapiEntity, Article, Flashnote } from '@blockworks/platform/src/api/strapi/models';

// blockworks research image
const defaultFeaturedImg = {
    id: '1374',
    meta: null,
    name: 'Copy of Research report - cover graphics-8.jpg',
    alternativeText: null,
    caption: null,
    width: 960,
    height: 540,
    formats: {
        small: {
            ext: '.jpg',
            url: 'https://impressive-horses-5641a8b530.media.strapiapp.com/small_Copy_of_Research_report_cover_graphics_8_e87914298c.jpg',
            hash: 'small_Copy_of_Research_report_cover_graphics_8_e87914298c',
            mime: 'image/jpeg',
            name: 'small_Copy of Research report - cover graphics-8.jpg',
            path: null,
            size: 12.24,
            width: 500,
            height: 281,
        },
        medium: {
            ext: '.jpg',
            url: 'https://impressive-horses-5641a8b530.media.strapiapp.com/medium_Copy_of_Research_report_cover_graphics_8_e87914298c.jpg',
            hash: 'medium_Copy_of_Research_report_cover_graphics_8_e87914298c',
            mime: 'image/jpeg',
            name: 'medium_Copy of Research report - cover graphics-8.jpg',
            path: null,
            size: 22.54,
            width: 750,
            height: 422,
        },
        thumbnail: {
            ext: '.jpg',
            url: 'https://impressive-horses-5641a8b530.media.strapiapp.com/thumbnail_Copy_of_Research_report_cover_graphics_8_e87914298c.jpg',
            hash: 'thumbnail_Copy_of_Research_report_cover_graphics_8_e87914298c',
            mime: 'image/jpeg',
            name: 'thumbnail_Copy of Research report - cover graphics-8.jpg',
            path: null,
            size: 3.83,
            width: 245,
            height: 138,
        },
    },
    hash: 'Copy_of_Research_report_cover_graphics_8_e87914298c',
    ext: '.jpg',
    mime: 'image/jpeg',
    size: 30.19,
    url: 'https://impressive-horses-5641a8b530.media.strapiapp.com/Copy_of_Research_report_cover_graphics_8_e87914298c.jpg',
    previewUrl: null,
    provider: 'strapi-provider-upload-strapi-cloud',
    provider_metadata: null,
    createdAt: '2023-09-28T15:26:59.761Z',
    updatedAt: '2023-09-28T15:27:49.520Z',
    blurhash: 'U24o1d~qayxu~q%Moft7D%M{oft7~q%Moft7',
};

export const mergeArticlesAndFlashnotes = (
    articles: SafeStrapiEntity<Article>[],
    flashnotes: SafeStrapiEntity<Flashnote>[],
) => {
    const flashnotesWithImgs = flashnotes.map(flashnote => {
        if (!flashnote.featuredImage?.url || flashnote.featuredImage?.url === '') {
            return {
                ...flashnote,
                featuredImage: defaultFeaturedImg,
            };
        }
        return flashnote;
    });
    const joined = [...articles, ...flashnotesWithImgs];
    joined.sort((a, b) => {
        if (!a.publishedAt) return 1;
        if (!b.publishedAt) return -1;
        if (a.publishedAt < b.publishedAt) return 1;
        if (a.publishedAt > b.publishedAt) return -1;
        return 0;
    });
    return joined;
};
