import * as React from 'react';

import { SafeStrapiCollection, SafeStrapiEntity, Article, Flashnote } from '@blockworks/platform/src/api/strapi/models';

import Pagination from '@/components/controls/Pagination';
import Post, { PostImageSize } from '@/components/section/Post/Post';
import PostList from '@/components/section/PostList';
import ResearchConfig from '@/ResearchConfig';

export interface IPaginationInfo {
    pages: number;
    total: number;
}

type FrontPageProps = {
    posts: SafeStrapiCollection<Article | Flashnote> | null;
    currentPage: number;
    postsPerPage: number;
    featuredPost?: SafeStrapiEntity<Article | Flashnote>;
    paginationInfo: IPaginationInfo;
    children: React.ReactNode | JSX.Element;
};

const FrontPage = (props: FrontPageProps) => {
    const { featuredPost, posts, currentPage, postsPerPage, children, paginationInfo } = props;

    const filteredPosts = React.useMemo(() => {
        if (featuredPost && posts) {
            // Skip featured posts since it is displayed separately
            return posts.filter(post => post.slug !== featuredPost.slug);
        }
        return posts || [];
    }, [featuredPost, posts]);

    return (
        <div className="relative">
            <div className="flex space-x-8 mb-8">
                <div className="h-full w-full md:w-7/12 2xl:w-6/12 3xl:w-5/12 featured">
                    {featuredPost && <Post article={featuredPost} imageSize={PostImageSize.big} showExcerpt />}
                </div>
                <div className="hidden md:block md:h-full md:w-5/12 2xl:w-6/12 3xl:w-7/12">
                    <>{children}</>
                </div>
            </div>

            <div className="grid gap-8 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                <PostList imageSize={PostImageSize.small} posts={filteredPosts} />
            </div>

            <Pagination
                baseUrl={ResearchConfig.siteUrl}
                urlGenerator={page => `?page=${page}`}
                postsPerPage={postsPerPage}
                totalPosts={paginationInfo.total}
                paginate={() => {}}
                currentPage={currentPage}
                pageOffset={1}
                siblingCount={1}
                totalPages={paginationInfo.pages}
            />
        </div>
    );
};

export default FrontPage;
