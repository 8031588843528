import React from 'react';

import PostSkeleton from '@/components/section/Post/PostSkeleton';
import Top10AssetsSkeleton from '@/components/section/Top10Assets/Top10AssetsSkeleton';

const FrontPageSkeleton = () => {
    return (
        <div className="relative">
            <div className="flex space-x-8 mb-8">
                <div className="h-full w-full md:w-7/12 2xl:w-6/12 3xl:w-5/12 featured">
                    <PostSkeleton imageSize="big" />
                </div>

                <div className="hidden md:block md:h-full md:w-5/12 2xl:w-6/12 3xl:w-7/12">
                    <Top10AssetsSkeleton count={15} />
                </div>
            </div>

            <div className="grid gap-8 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                <PostSkeleton imageSize="small" multiple={true} />
            </div>
        </div>
    );
};

export default FrontPageSkeleton;
