import React from 'react';

import HorizontalSkeletonLine from '@core/components/display/skeleton/HorizontalSkeletonLine';

import Card from '../../display/Card';

const Top10AssetsSkeleton = (props: { count: number } = { count: 15 }) => {
    return (
        <Card className="p-0 flex overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200 text-sm">
                <tbody>
                    {new Array(props.count).fill('top10').map((value, index) => (
                        <tr key={`skeleton-${value}-${index}`}>
                            <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500 text-center">
                                <HorizontalSkeletonLine height={4} />
                            </td>
                            <td className="px-4 py-2 whitespace-nowrap font-semibold text-sm text-text-dark">
                                <HorizontalSkeletonLine height={4} />
                            </td>
                            <td className="px-4 py-2 whitespace-nowrap text-sm text-text-dark text-right">
                                <HorizontalSkeletonLine height={4} />
                            </td>
                            <td className="px-4 py-2 whitespace-nowrap text-xs text-gray-700 text-right">
                                <HorizontalSkeletonLine height={4} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </Card>
    );
};

export default Top10AssetsSkeleton;
