import { useEffect } from 'react';

import { GetServerSideProps, GetServerSidePropsContext } from 'next';
import useLocalStorage from '@core/utils/storage/useLocalStorage';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { StrapiEntities, Article, Flashnote, SafeStrapiCollection } from '@blockworks/platform/src/api/strapi';

import { getServerSidePropsWithCatcher, withExceptionCatcher } from '@/components/hoc/withExceptionCatcher';
import FrontPage from '@/components/pages/frontpage/FrontPage';
import ResearchConfig from '@/ResearchConfig';
import { mergeArticlesAndFlashnotes } from '@/utils/functions/mergeArticlesAndFlashnotes';

import FrontPageSkeleton from './FrontPageSkeleton';

const POSTS_PER_PAGE = 15;
interface Props {
    posts: SafeStrapiCollection<Article>;
    currentPage?: number;
    totalPosts?: number;
    totalPostPages?: number;
    flashnotes: SafeStrapiCollection<Flashnote>;
}

const Top10Assets = dynamic(() => import('@/components/section/Top10Assets/Top10Assets'), {
    loading: () => <p>Loading Top10Assets...</p>,
});

export const getServerSideProps: GetServerSideProps<any> = getServerSidePropsWithCatcher(
    async (context: GetServerSidePropsContext) => {
        const page = context.query.page ? parseInt(context.query.page as string, 10) : 1;

        const fetchArticles = StrapiEntities.article.fetchMany({
            sort: 'publishedAt:desc',
            populate: '*',
            pagination: { page: page || 1, pageSize: POSTS_PER_PAGE || 10 },
        });
        const fetchFlashnotes = StrapiEntities.flashnote.fetchMany({
            sort: 'publishedAt:desc',
            populate: '*',
        });
        const [articlesRes, flashnotesRes] = await Promise.allSettled([fetchArticles, fetchFlashnotes]);
        const articles = articlesRes.status === 'fulfilled' ? articlesRes.value : [];
        const flashnotes = flashnotesRes.status === 'fulfilled' ? flashnotesRes.value : [];
        return {
            props: {
                meta: {
                    title: 'Research | Blockworks Research',
                    description:
                        'Crypto has evolved into distinct sectors: DeFi, Gaming, NFTs, Bitcoin, Stablecoins, DAOs and more. Blockworks Research brings you protocol and sector specialists who guide you through each area so you can build tailored investment strategies for each.',
                    canonical_url: `${ResearchConfig.siteUrl}`,
                },
                posts: articles,
                currentPage: articles.pagination?.page,
                totalPosts: articles.pagination?.total,
                totalPostPages: articles.pagination?.pageCount,
                flashnotes,
            },
        };
    },
);

export const FrontPageController = (props: Props) => {
    const router = useRouter();

    const { storedValue: callbackUrl, setValue: setCallbackUrl } = useLocalStorage('auth.callbackUrlNew');
    const { posts: articles, currentPage, totalPosts, totalPostPages } = props;
    const posts = currentPage === 1 ? mergeArticlesAndFlashnotes(articles, props.flashnotes) : articles;
    const featuredPost = posts && posts.length > 0 ? posts[0] : undefined;

    // Redirect hack:
    //  - SignIn sets a local storage variable named auth.callbackUrl
    //  - Google sign in automatically redirects to the frontpage (/)
    //  - If we have saved a callbackUrl which is not the frontapge then redirect there.

    useEffect(() => {
        if (router && callbackUrl && typeof callbackUrl === 'string') {
            setCallbackUrl('');
            // Routing to the same page can break next router in some cases
            const [callbaclUrlPathname] = callbackUrl.split('?');
            if (router.pathname !== callbaclUrlPathname) {
                router.push(callbackUrl).catch(() => {});
            }
        }
    }, [router, setCallbackUrl, callbackUrl]);

    if (callbackUrl) {
        return <></>;
    }

    if (!posts) {
        return <FrontPageSkeleton />;
    }

    return (
        <FrontPage
            posts={posts}
            currentPage={currentPage ?? 1}
            postsPerPage={POSTS_PER_PAGE}
            featuredPost={featuredPost}
            paginationInfo={{
                pages: totalPostPages ?? 0,
                total: totalPosts ?? 0,
            }}
        >
            <Top10Assets supported count={15} className="mb-0 max-h-96 xl:max-h-full scrollbar-hide" />
        </FrontPage>
    );
};

export default withExceptionCatcher(FrontPageController);
