import { useMemo } from 'react';

const DOTS = '...';

export type PaginationProps = {
    totalPosts: any;
    postsPerPage: any;
    siblingCount: any;
    currentPage: any;
};

const range = (start: number, end: number) => {
    const length = end - start + 1;
    return Array.from({ length }, (_, idx) => idx + start);
};

const usePagination = (props: PaginationProps) => {
    const { totalPosts, postsPerPage, siblingCount = 1, currentPage } = props;

    const paginationRange = useMemo(
        // @ts-ignore
        // eslint-disable-next-line consistent-return
        () => {
            const totalPageCount = Math.ceil(totalPosts / postsPerPage);

            const totalPageNumbers = siblingCount + 5;

            if (totalPageNumbers >= totalPageCount) {
                return range(1, totalPageCount);
            }

            const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
            const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPageCount);

            const shouldShowLeftDots = leftSiblingIndex > 2;
            const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

            const firstPageIndex = 1;
            const lastPageIndex = totalPageCount;

            if (!shouldShowLeftDots && shouldShowRightDots) {
                const leftItemCount = 3 + 2 * siblingCount;
                const leftRange = range(1, leftItemCount);

                return [...leftRange, DOTS, totalPageCount];
            }

            if (shouldShowLeftDots && !shouldShowRightDots) {
                const rightItemCount = 3 + 2 * siblingCount;
                const rightRange = range(totalPageCount - rightItemCount + 1, totalPageCount);
                return [firstPageIndex, DOTS, ...rightRange];
            }

            if (shouldShowLeftDots && shouldShowRightDots) {
                const middleRange = range(leftSiblingIndex, rightSiblingIndex);
                return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
            }
        },
        [totalPosts, postsPerPage, siblingCount, currentPage],
    );

    return paginationRange;
};

export default usePagination;
