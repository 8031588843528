import { ArrowLongLeftIcon, ArrowLongRightIcon } from '@heroicons/react/20/solid';
import Link from '@core/components/display/links/Link';

import { classNames } from '@/utils/functions/class-names';
import usePagination from '@/utils/hooks/usePagination';

type PropsArrows = {
    previous: true;
    next: true;
    firstPage: false;
};

type PageGenerator = {
    (page: number): string;
};

interface Props {
    arrows?: PropsArrows | null;
    baseUrl: string;
    urlGenerator: PageGenerator;
    paginate: any;
    currentPage: number;
    postsPerPage: number;
    totalPosts: number;
    totalPages?: number;
    pageOffset?: number;
    siblingCount?: number;
    type?: string;
}

const Pagination = (props: Props) => {
    const DOTS = '...';

    const { pageOffset, totalPosts, siblingCount, postsPerPage, currentPage, totalPages, type } = props;
    const startingIndex = pageOffset ?? 1;

    const paginationRange = usePagination({
        currentPage,
        totalPosts,
        siblingCount,
        postsPerPage,
    });

    const arrows = props.arrows ?? {
        previous: true,
        next: true,
        firstPage: currentPage > startingIndex,
    };

    // incase the pagination component doesn't pass a total pages props
    const calculateTotalPages = totalPages ?? Math.ceil(totalPosts / postsPerPage);

    const previousArrowDisabled = currentPage === startingIndex;

    const nextArrowDisabled = totalPages && totalPages > 1 ? currentPage >= totalPages : true;
    const nextNewsArrowDisabled = postsPerPage < 12;

    if (paginationRange && paginationRange.length <= 1) {
        return <div className="my-8"></div>;
    }

    return (
        <nav className="my-8 border-t border-gray-200 flex items-center justify-between">
            {arrows.previous && (
                <div className="-mt-px w-0 flex-1 flex">
                    {!previousArrowDisabled ? (
                        <Link
                            href={
                                currentPage > startingIndex
                                    ? props.urlGenerator(currentPage - startingIndex)
                                    : props.urlGenerator(startingIndex)
                            }
                            className="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
                            onClick={() => props.paginate(currentPage - 1)}
                        >
                            <ArrowLongLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                            Previous
                        </Link>
                    ) : (
                        <button className="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-300">
                            <ArrowLongLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                            Previous
                        </button>
                    )}
                </div>
            )}
            {type === 'newsPagination' ? (
                <div className="hidden md:-mt-px md:flex">
                    <Link
                        href={props.urlGenerator(currentPage)}
                        className="border-indigo-500 text-indigo-600 hover:text-gray-700 hover:border-gray-300 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium"
                    >
                        {currentPage}
                    </Link>
                </div>
            ) : (
                <div className="hidden md:-mt-px md:flex">
                    {paginationRange?.map((pageNumber: any, index: number) => {
                        if (pageNumber === DOTS) {
                            return (
                                <li
                                    key={index}
                                    className="hover:text-gray-700 hover:border-gray-300 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium"
                                >
                                    &#8230;
                                </li>
                            );
                        }
                        return (
                            <Link
                                key={index}
                                replace={false}
                                href={props.urlGenerator(pageNumber)}
                                onClick={() => props.paginate(pageNumber)}
                                className={classNames(
                                    currentPage === pageNumber ? 'border-indigo-500 text-indigo-600 ' : '',
                                    ' hover:text-gray-700 hover:border-gray-300 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium',
                                )}
                            >
                                {pageNumber}
                            </Link>
                        );
                    })}
                </div>
            )}
            {type === 'newsPagination'
                ? arrows.next && (
                      <div className="-mt-px w-0 flex-1 flex justify-end">
                          {!nextNewsArrowDisabled ? (
                              <Link
                                  href={props.urlGenerator(currentPage + 1)}
                                  className="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
                                  onClick={() => props.paginate(currentPage + 1)}
                              >
                                  Next
                                  <ArrowLongRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                              </Link>
                          ) : (
                              <button className="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-300">
                                  Next
                                  <ArrowLongRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                              </button>
                          )}
                      </div>
                  )
                : arrows.next && (
                      <div className="-mt-px w-0 flex-1 flex justify-end">
                          {!nextArrowDisabled || currentPage < calculateTotalPages ? (
                              <Link
                                  href={props.urlGenerator(currentPage + 1)}
                                  className="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
                                  onClick={() => props.paginate(currentPage + 1)}
                              >
                                  Next
                                  <ArrowLongRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                              </Link>
                          ) : (
                              <button className="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-300">
                                  Next
                                  <ArrowLongRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                              </button>
                          )}
                      </div>
                  )}
        </nav>
    );
};

export default Pagination;
